<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm8>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        <span>Roles</span>
                        <v-spacer></v-spacer>
                        <add-button @action="$router.push({name: 'add-role'})" permission="roles-create">
                            New Role
                        </add-button>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :items="form.items.data"
                            :search="search"
                            :options.sync="pagination"
                            footer-props.items-per-page-options="rowsPerPageItems"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template v-slot:item={item}>
                            <tr>
                                <td class="text-left">
                                    <select class="rank_update" name="" v-model="item.rank"
                                            @change="updateRank($event,item.id)">
                                        <option disabled selected>-</option>
                                        <option :value="n" :selected="n===item.rank"
                                                v-for="n in form.items.meta.total"
                                                :key="n">
                                            {{n}} <span
                                                v-if="selectedRanks.includes(n) && n!==item.rank">&#10003;</span>
                                        </option>
                                    </select>
                                </td>
                                <td class="text-xs-left">{{ item.name }}</td>
                                <!-- <td class="text-center">{{ item.permissions }}</td> -->
                                <!-- <td class="text-center">{{ item.users }}</td> -->
                                <td class="text-right">
                                    <view-button  @action="form.dialog = true, getPermissions(item.id)"
                                                 permission="roles-permissions-read">
                                    </view-button>
                                    <edit-button color="warning"
                                                 icon="edit"
                                                 @agree="$router.push({name:'edit-role',params:{roleSlug:item.slug}})"
                                                 permission="roles-update">
                                    </edit-button>
                                    <delete-button v-if="item.my && item.slug!=='super'"
                                                   permission="roles-delete"
                                                   @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer/>
                        <!--<v-pagination v-model="pagination.page" :length="form.items.meta.last_page"></v-pagination>-->
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex xs12 sm4>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        <v-spacer/>
                        <v-btn icon ma-0 small @click="setData(true)">
                            <v-icon small>autorenew</v-icon>
                        </v-btn>
                    </v-card-title>
                    <apexchart v-if="chartData.length" type="pie" :options="chartOptions" :series="chartData"/>
                </v-card>
                <v-card outlined class="primary--border" style="margin-top:10px">
                    <v-card-title class="title">
                        <v-spacer/>
                    </v-card-title>
                    <apexchart v-if="permissions.length" type="pie" :options="permissionChart" :series="permissions"/>
                </v-card>
            </v-flex>
            


            <v-dialog v-model="form.dialog" scrollable persistent max-width="600px">
                <v-card>
                    <br>

                    <v-card-text class="pb-1 pt-0" style="height: 360px;">
                        <v-data-table
                                :headers="[
                                {text: 'Modules', align: 'left', value: 'name',width:20},
                                {text: 'Permissions', align: 'right', value: 'permissions', sortable: false},
                            ]"
                                hide-default-footer
                                :items="permissions"
                        >
                            <template v-slot:item={item}>
                                <tr>
                                    <td class="text-xs-left">{{ item.name }}</td>
                                    <td class="text-xs-right">
                                        <v-chip class="mr-1 mb-1 mt-1"
                                                color="primary"
                                                outlined
                                                small
                                                label
                                                v-for="(permission,key) in item.per_name.split(',')" :key="key"
                                                :close="false">
                                            {{permission}}
                                        </v-chip>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="form.dialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({dialog: false}, '/api/roles'),
            search: null,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 50,
                descending:false
            },
            headers: [
                {text: 'Rank', align: 'left', value: 'rank_order', width: 80, sortable: false},
                {text: 'Name', align: 'left', value: 'name', sortable: false, width: 150},
                {text: 'Action', align: 'right', sortable: false, width: 150}
            ],
            chartData: [],
            chartOptions: {
                title: {
                    text: 'Role-wise Users',
                    align: 'center'
                },
                legend: {
                    show: false,
                    floating: true,
                },
                labels: []
            },
            permissionChart:{
                 title: {
                    text: 'Permission-wise Users',
                    align: 'center'
                },
                legend: {
                    show: false,
                    floating: true,
                },
                labels: []
            },
            permissions: [],
            refresh_staus:false
        }),
        computed: {
            selectedRanks() {
                let output = [];
                this.form.items.data.filter(function (item) {
                    if (item.rank !== 0)
                        output.push(item.rank);
                });
                return output;
            }
        },
        // watch: {
        //     'pagination': function () {
        //         this.get();
        //     },
        // },
        mounted(){
            // this.setChart();
            this.setData();
        },
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
                    if (typeof json[key] === 'object') {
                        let vals = [...json[key]];
                        if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&');
            },
            getPermissions(id) {
                this.permissions = [];
                this.$rest.get('/api/roles/' + id + '/permissions').then(({data}) => {
                    if (data.status === true) {
                        this.permissions = data.permissions;
                    }
                });
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                query = query+'&slim=true'
                this.form.get(null, query).then(({data}) => {
                    let $this = this;
                    let output = [];
                    data.data.map(function (item) {
                        if (item.slug === 'super') {
                            if ($this.$auth.getRole() === 'super') {
                                output.push(item);
                            }
                        } else {
                            output.push(item);
                        }
                    });
                    this.form.items.data = output;
                    this.pagination.totalItems = data.meta.total;

                })
            },
            updateRank(e, roleId) {
                this.$rest.put(`api/roles/${roleId}/rank`, {rank: e.target.value}).then(({data}) => {
                    this.get();
                    this.$events.fire('notification', {message: data.message, status: 'success'})
                }).catch(err => {
                    if (err.response && err.response.data)
                        this.$events.fire('notification', {message: err.response.data.message, status: 'error'})
                })
            },

            async setData(status=null){
              await this.get();
            if(localStorage.getItem('role_user_count')===null || status){
                this.$rest.get('/api/roles?slim=false')
                .then((res)=>{
                    this.setChart(res.data.data)
                    window.localStorage.setItem('role_user_count',JSON.stringify( res.data.data));
                })
                .catch((e)=>{
  
                })

            }else{
                const role_user_count = JSON.parse(window.localStorage.getItem('role_user_count'))
                this.setChart(role_user_count)
            }
          },
            setChart(data) {
                let labels = [];
                let chartData = [];
                let permission = [];
                data.map(item => {
                    if (item.name !== 'Guardian') {
                        
                        labels.push(item.name);
                        chartData.push(item.users);
                        permission.push(item.permissions)
                    }
                });

                this.chartOptions.labels = labels;
                this.permissionChart.labels = labels;
                this.permissions = permission;
                this.chartData = chartData;
            },
        }
    }
</script>

<style lang="scss">
    select.rank_update {
        outline: none;
    }
</style>